$__primarycolor:#00b0f0;
$__primarycolor-hover: #fd8e2d;
$__secondarycolor:#1f1f1f;
$__whitecolor:#fff;
$__orangecolor:#fbc418;
$__deep__skyblue: #42c0fb;
$__blue: #15558d;
$__white: #ffffff;
$__basecolor:#2c3038;
$__dark-blue: #2E3842;
$__slate-blue: #2d3b48;
$__dark-slate-blue: #474648;
$__darkblues: #453a94;
$__darkpurple: #667eea;
$__dark__blues: #00c5fb;
$__dark__maroon: #f43b48;
$__gray: #333333;
$__grayback: #404040;
$__greylight:#68616D;
$__grayblack: #34444c;
$__dark__gray: #888888;
$__dark__graytext: #8e8e8e;
$__mix__gray: #f7f7f7;
$__dark-gray: #171717;
$__light-gray: #dcdcdc;
$__spaced-gray: #d5d5d5;
$__goose-gray: #cccccc;
$__grayblacks: #16191c;
$__light__goose: #dddddd;
$__light-goose-gray: #dedfe0;
$__light-silver: #f0f0f0;
$__black: #000000;
$__blackdark: #575757;
$__smokey-gray: #757575;
$__smokey-grays: #76838f;
$__mix__blue__gray: #455a64;
$__mix__grays: #8c8c8c;
$__light__smokey-gray: #666;
$__dark__smokeygray: #727272;
$__gray__11: #F4F4F4;
$__celeste: #0de0fe;
$__dark-celeste: #01cae4;
$__light__celeste: #20e3ff;
$__light-pale-blue: #f8f9fa;
$__red: #fb1612;
$__green: #28a745;
$__dull-gold: #f4c150;
$__dark__blue: #272b41;
$__sky__blue: #0d6efd; 
$__gray__cloud: #999;
$__dark__gray__cloud: #9f9f9f;
$__steel__blue: #216ef4;
$__steel__blues: #0253cc;
$__granite: #858585;
$__light__granite: #8a8a8a;
$__granite__gary: #797979;
$__platinum: #f9f9f9;
$__jet__gray: #C0C0C0;
$__metallic-silver: #e3e3e3;
$__light__metallic: #dfdfdf;
$__dark-inkblue: #3474ac;
$__info__light: #1db9aa;
$__primary__light: #2196f3;
$__danger__light: #e63c3c;
$__warning__light: #f39c12;
$__success__light: #26af48;
$__purple__light: #c580ff;
$__default__light: #283447;
$__default__blues: #263238;
$__default__black: #263238;
$__light__black: #2e3840;
$__default__color:#555a64;
$__light__metalic: #e9e9e9;
$__blue__angel: #cfcfcf;
$__Light__blue__angel: #dcddea;
$__grapefruit: #d9534f;
$__orangefruit: #FF7444;
$__light__grapefruit: #d43f3a;
$__mild__grapefruit: #e48784;
$__violet__pink: #ff4877;
$__ash__gray: #a0a0a0;
$__ash__grays: #bbc4cc;
$__dark__ash__gray: #555;
$__light__white: #fafafa;
$__dark__light: #f5f5f5;
$__cloud__gray: #eeeeee;
$__gray__wolf: #3e3e3e;
$__wolf: #6c757d;
$__light__wolf: #a3a3a3;
$__water: #f5f7fc;
$__lava__red: #dc3545;
$__dark__red: #ef5350;
$__light__red: #fef7f6;
$__rating__red: #ad0d20;
$__metalic__gray: #bbb;
$__bright__gold: #f2b600;
$__bright__golds: #dccc00;
$__water__white: #fcfcfc;
$__light__water__white: #f3f3f3;
$__light__blue__gray: #f5f5f6;
$__baby__blue: #e0e3e4;
$__light__columbia__blue: #d2dde9;
$__lights__blue: #e8ecec;
$__light__battle__gary: #aaa;
$__light__battle__garys: #9e9e9e;
$__light__gary: #dee2e6;
$__dark__battle__gary: #777;
$__royal__blue: #2c80ff;
$__zombie__green: #00d285;
$__dark__green: #00a100;
$__dark__marble__blue: #495463;
$__mix__white: #fbfbfb;
$__night: #3d3d3d;
$__dark__cloud: #d4d4d4;
$__light__cloud: #e5e5e5;
$__light__clouds: #ededed;
$__lapis__blue: #3a559f;
$__dark__lava__red: #dd4b39;
$__dark__beige: #b8b8b8;
$__iridium: #676767;
$__light__chilli__pepper: #ed7d31;
$__successcolor: #55ce63;
$__successgreen: #35BA67;
$__successcolor-hover: #699834;
$__infocolor: #009efb;
$__infocolor-hover: #028ee1;
$__warningcolor: #ffbc34;
$__orangecolors: #ffb300;
$__orangecolorslight: #fdfcf3;
$__warningcolor-hover: #e9ab2e;
$__dangercolor: #f62d51;
$__dangercolor-hover: #e63333;
$__purplecolor: #9368e9;
$__purpletext: #7460ee;
$__purpletextlight: #f1effd;
$__cherry__red: #f06060;
$__lime__green: #55ce63;
$__aquamarine: #40dda4;
$__lightgreens: #4caf50;
$__lightgreenstext: #edf7ee;
$__spring__green: #2ab934;
$__dark__ash: #212529;
$__dark__candy: #ababab;
$__light__candy: #e6e6e6;
$__light__candys: #E6E9E8;
$__candycolor: #a6a6a6;
$__ligt__carbon: #979797;
$__cantaloupe: #00b0f0;
$__cantaloupelight: #fff5ec;
$__dark__plum__purple: #4E4852;
$__turquoise: #0db9f2;
$__beige: #dbdbdb;
$__light__beige: #e4e4e4;
$__light__vampire: #f1f1f1;
$__cyan__blue: #0dd9f9;
$__cyan: #5ae8ff;
$__slick__arrow: #0B0B0B;
$__sea__gray: #e2e5e8;
$__mild___gray: #eaeaea;
$__light__smokey-gray__gray: #737373;
$__light__blue__jey: #1b5a90;
$__gray__powder: #ebebeb;
$__gray__powders: #b7c0cd;
$__gray__opaque: #e4e8eb;
$__dodger__blue: #177dff;
$__text-secondary: #b8bdc1;
$__ferriari__red: #e0001a;
$__sandstone: #bdbdbd;
$__frog__blue: #989c9e;
$__taupe__black: #616161;
$__windows__blue: #4b75bd;
$__tomred: #fe5240;
$__saffron: #ffc107;
$__yellow: #ffd200;
$__light__sage: #f2f2f2;
$__cream: #ffffcc;
$__alicegray: #f4f4f4;
$__plum__gray: #878787;
$__midnight__gray: #66676b;
$__plum__black: #1f1f1f;
$__blue__gray: #f4f4f5;
$__fire__red: #f83f37;
$__grey__color:#8d8d8d;
$__grey__light:#595959;
$__snow__white: rgba(255, 255, 255, 0.15);
$__snow__dark: rgba(255, 255, 255, 0.7);
$__snow__light: #e9ecef;
$color_1: rgba(0, 0, 0, 0.85);

$darkcolor_1: #bbc4cc;